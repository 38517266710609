<template>

  <div id="loginPage">
    <el-row>
      <el-col :span="24" align="center">
        <div class="logo-bg">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{lang}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="ja">日本語</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24" align="center">
        <div class="main-bg">
          <div class="slogan">人財採用の課題解決から<br>その先の支援へ</div>
          <div class="slogan-info" style="width:600px;margin-left:-100px;">
            {{$t('login.slogan')}}<br>
            <br>
          </div>

          <transition name="el-fade-in-linear">
            <div id="loginForm" v-show="show" style="margin-top: -100px;">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" style="margin-top: 30px;">
                <el-form-item label="" prop="userName">
                  <el-input
                    placeholder="メールアドレス"
                    v-model="ruleForm.userName"
                    clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                  <el-input
                    :placeholder="$t('login.password')"
                    v-model="ruleForm.password"
                    show-password
                    clearable>
                  </el-input>
                </el-form-item>
              
                <el-form-item style="text-align:center;">
                  <el-button
                    type="primary"
                    @click="submitRuleForm('ruleForm')"
                    style="width:100%" 
                    round>{{$t('login.login')}}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>

          </transition>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24" align="center">
        <div class="footer">
          (C) Precious Partners Co., Ltd.
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { login } from "@/api/account";
import { mapState, mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "Login",
  data: () => {
    return {
      ROUTER_PATH: ROUTER_PATH,
      lang: "",
      ruleForm: {
        userName: "",
        password: ""
      },
      rules: {
        userName: [
          { required: true, message: "メールアドレスを入力してください。", trigger: "blur" }
        ],
        password: [{ required: true, message: "パスワードを入力してください", trigger: "blur" }]
      },
      show: true
    };
  },
  mounted() {
    this.lang = "日本語";
    this.$i18n.locale = "ja";
    this.$cookies.set("language", "ja", 60 * 60 * 24 * 30);
    if (
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
      ) &&
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      )
    ) {
      this.$router.push(ROUTER_PATH.HOME);
    }
  },
  methods: {
    ...mapMutations([mutationsType.INIT_ACCOUNT_INFO]),
    handleCommand(command) {
        this.lang = "日本語";
        this.$i18n.locale = "ja";
    },
    submitRuleForm(ruleFormName) {
      this.$refs[ruleFormName].validate(valid => {
        if (valid) {
          login({
            ...this.ruleForm
          }).then(response => {
            this.$message({
              type: "success",
              message: response.message
            });
            localStorage.setItem(
              LOCAL_STORAGE_KEY.RPA_TOKEN,
              response.data.token
            );
            localStorage.setItem(
              LOCAL_STORAGE_KEY.RPA_USER_ID,
              response.data.id
            );
            localStorage.setItem(
              LOCAL_STORAGE_KEY.RPA_TOKEN_EXPIRE,
              Date.now() + 24*60*60*1000
            );

            this.$router.push(
              isNullOrUndefinedOrEmpty(this.loginPrePath)
                ? ROUTER_PATH.HOME
                : this.loginPrePath
            );
          });
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState(["loginPrePath"])
  }
};
</script>

<style>
#loginPage {
  display: flex;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
}
#loginPage .container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loginForm {
  box-sizing: border-box;
  width: 500px;
  height: 300px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top:-100px;
  left:360px;
  position:relative;
  background-color: #fff;
}

#loginForm .forgetPassword{
  margin:0px 0px 10px 0px;
  text-align: right;
}

.logo-bg {
  background-image: url(../../assets/images/logo.png);
  background-repeat: no-repeat;
  background-size: 400px;
  width: 80%;
  height: 45px;
  margin-top: 35px;
  text-align: right;
}

.main-bg {
  background-image: url(../../assets/images/image1.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 90%;
  height: 600px;
  margin-top: 30px;
}

.footer { 
  width:90%;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background-color: #333a40;
  color: #fff;
  font-size: 12px;
}

.slogan { 
  font-size: 36px;
  font-weight: bolder;
  color: #333a40;
  top:120px;
  left:-295px;
  position:relative;
}

.slogan-info { 
  font-size: 18px;
  color: #333a40;
  top:170px;
  left:-230px;
  position:relative;
  text-align: left;
  width: 500px;
  line-height: 26px;
}

.text-center{
  text-align: center;
}

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

</style>
