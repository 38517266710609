import axiosRequest from "@/utils/axiosRequest";

  export function login(data) {
    return axiosRequest({
        url: `/adminuser/login`,
        data,
        method: 'post'
    })
  }
  
  export function getUserInfo(data) {
    return axiosRequest({
        url: `/adminuser/getUserInfo`,
        data,
        method: 'post'
    })
  }